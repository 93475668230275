import { Still, continueRender, delayRender } from "remotion";
import { CompanyCard } from "./CompanyCard";
import { companyCardSchema } from "./CompanyCard";
import { IntroCard } from "./IntroCard";
import { useCallback, useEffect, useState } from "react";

const ATTIO_TOKEN =
  "4727cefe42dfd23ba3795b05bc00ee8338b62a22d7bcbd3e9318a7e3d05d7b57";

function getFlagEmoji(countryCode: string) {
  const codePoints = countryCode
    .toUpperCase()
    .split("")
    .map((char: string) => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
}

function usScoreClassification(score: number) {
  if (score < 3.33) return "Low";
  if (score < 6.66) return "Medium";
  return "High";
}

function beautifyNumber(number: number) {
  if (number > 1000000000) return (number / 1000000000).toFixed(1) + "B";

  if (number > 1000000) return (number / 1000000).toFixed(1) + "M";

  if (number > 1000) return (number / 1000).toFixed(1) + "K";

  return number.toFixed(1);
}

export const RemotionRoot: React.FC = () => {
  const [list, setList] = useState<any>({});
  const [records, setRecords] = useState<any[]>([]);
  const [handle] = useState(() => delayRender());

  const fetchAllData = useCallback(
    async (waitHandle) => {
      try {
        const responseLists = await fetch("https://api.attio.com/v2/lists", {
          headers: {
            Authorization: `Bearer ${ATTIO_TOKEN}`,
          },
        });

        let { data: lists } = await responseLists.json();

        lists = lists.filter(
          (list: any) =>
            list.name.toLowerCase().includes("top 10") ||
            list.name.toLowerCase().includes("top10")
        );

        lists = lists.sort((a: any, b: any) =>
          a.created_at > b.created_at ? -1 : 1
        );

        if (!lists.length) {
          throw new Error("No matching lists found");
        }

        const list = lists[0];
        setList(list);

        const responseEntries = await fetch(
          `https://api.attio.com/v2/lists/${list?.id?.list_id}/entries/query`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${ATTIO_TOKEN}`,
            },
            body: JSON.stringify({
              sorts: [
                {
                  attribute: "growth_score",
                  direction: "desc",
                },
              ],
            }),
          }
        );

        const { data: entries } = await responseEntries.json();
        const recordIds = entries.map((entry: any) => entry.parent_record_id);

        const records = await Promise.all(
          recordIds.map(async (recordId: string) => {
            const responseRecord = await fetch(
              `https://api.attio.com/v2/objects/companies/records/${recordId}`,
              {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${ATTIO_TOKEN}`,
                },
              }
            );
            const { data: record } = await responseRecord.json();
            return record;
          })
        );

        const sortedRecords = records.sort((a, b) => {
          return (
            a.values.growth_score_10?.[0]?.value -
            b.values.growth_score_10?.[0]?.value
          );
        });

        console.log({ list, records: sortedRecords });
        setRecords(sortedRecords);

        continueRender(handle);

        return { attioList: list, attioRecords: sortedRecords };
      } catch (error) {
        console.error("Error fetching data: ", error);
        continueRender(waitHandle);
        throw error;
      }
    },
    [handle]
  );

  useEffect(() => {
    fetchAllData(handle);
  }, [fetchAllData, handle]);

  return (
    <>
      {" "}
      {records.length > 0 ? (
        <Still
          id="Slide-0"
          component={IntroCard}
          width={1080}
          height={1350}
          defaultProps={{
            title: list.name?.split("-")?.[0],
            date: list.name?.split("-")?.[1] || "",
            records: records,
          }}
        />
      ) : null}
      {records
        .reverse()
        .slice(0, 5)
        .reverse()
        .map((record: any, index: number) => (
          <Still
            key={`Card${index}`}
            id={`Slide-${index + 1}`}
            component={CompanyCard}
            width={1080}
            height={1350}
            schema={companyCardSchema}
            defaultProps={{
              title: list.name?.split("-")?.[0],
              date: list.name?.split("-")?.[1] || "",
              name: record?.values?.name[0]?.value,
              logoURL:
                record?.values?.logo_url_7?.[0]?.value ||
                "https://images.twinkl.co.uk/tw1n/image/private/t_630/u/ux/question-mark_ver_1.jpg",
              logoHeight: 100,
              description: record?.values?.description[0]?.value,
              domain: record?.values?.domains[0]?.domain,
              country: record?.values?.primary_location?.[0]?.country_code
                ? (new Intl.DisplayNames(["en"], { type: "region" }).of(
                    record?.values?.primary_location?.[0]?.country_code
                  ) as string)
                : "???",
              flag: record?.values?.primary_location?.[0]?.country_code
                ? getFlagEmoji(
                    record?.values?.primary_location?.[0]?.country_code
                  )
                : "🌍",
              growthScore:
                record?.values?.growth_score_10?.[0]?.value.toFixed(2),
              usPotential: usScoreClassification(
                record?.values?.us_score?.[0]?.value
              ),
              rank: 5 - index,
              progress: (index + 2) / (5 + 1),
              funding: beautifyNumber(
                record?.values?.total_funding_amount?.[0]?.currency_value
              ),
              stage:
                record?.values?.last_funding_status_46?.[0]?.option?.title.replace(
                  "_",
                  " "
                ),
            }}
          />
        ))}
    </>
  );
};

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }
